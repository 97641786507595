import React, { useContext } from 'react';

import { ModalDispatchContext } from '../../../context/modalContext';
import { Assets } from '../../../utils/assets';
import { PrimaryButtonLink } from '../../button';
import MaxWidth from '../../shared/MaxWidth';
import * as S from './CTAbox.styles';

const CTAbox: React.FC = () => {
  const setModalState = useContext(ModalDispatchContext);

  return (
    <S.Wrapper>
      <MaxWidth>
        <S.Container>
          <S.Left>
            <S.Title>
              Your most productive day starts now.
            </S.Title>
            <S.Text>
              Our science-first approach creates music that sounds different and affects your brain
              differently than any other music.
            </S.Text>
            <S.ButtonWrapper>
              <PrimaryButtonLink
                analyticsEvent={{ category: 'CTA', action: 'Signup Button Click' }}
                isAnchortag={false}
                variant='light'
                onClick={() => setModalState({ signupVisible: true })}
              >
                TRY BRAIN.FM FOR FREE
              </PrimaryButtonLink>
            </S.ButtonWrapper>
          </S.Left>
          <S.Right>
            <S.ImageWrapper>
              <S.Image
                alt={Assets.images.appReviewScore.alt}
                src={Assets.images.appReviewScore.url}
              />
              <S.Blur
                alt={Assets.images.appReviewBlur.alt}
                src={Assets.images.appReviewBlur.url}
              />
            </S.ImageWrapper>
          </S.Right>
        </S.Container>

        <S.DividerLine />
      </MaxWidth>
    </S.Wrapper>
  );
};

export default CTAbox;
