import { useState } from 'react';

import { QuestionPlusIcon } from './components/QuestionPlusIcon';
import * as S from './Question.styles';

export interface QuestionProps {
  question: string;
  answer: JSX.Element;
}

export const Question: React.FC<QuestionProps> = ({ question, answer }) => {
  const [open, setOpen] = useState(false);

  return (
    <S.Container>
      <S.QuestionContainer onClick={() => setOpen(!open)}>
        <QuestionPlusIcon isClose={open} />
        <S.Title>{question}</S.Title>
      </S.QuestionContainer>
      <S.Description isOpen={open}>{answer}</S.Description>
    </S.Container>
  );
};
