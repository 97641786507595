import Image from 'next/image';

import { Assets } from '../../../../../utils/assets';
import IconImage from './assets/download_mac_app_logo.webp';
import * as S from './styles';

type Props = {
  onClose: () => void;
  onNavigate: () => void;
};

export const DownloadMacAppModal = ({ onClose, onNavigate }: Props) => {
  return (
    <S.Wrapper>
      <S.Container>
        <S.ImageContainer>
          <Image
            alt="Download Mac App"
            height={120}
            objectFit="contain"
            src={IconImage}
            width={185}
          />
        </S.ImageContainer>

        <S.Column>
          <S.TextContainer>
            <S.Title>Introducing the Brain.fm Mac App</S.Title>
            <S.Subtitle>
              No more juggling browser tabs-just seamless, distraction-free productivity.
            </S.Subtitle>
          </S.TextContainer>

          <S.ButtonContainer onClick={onNavigate}>
            <S.Button>download brain.fm for desktop</S.Button>
          </S.ButtonContainer>
        </S.Column>
      </S.Container>

      <S.CloseButton>
        <Image
          alt="Close"
          height={24}
          src={Assets.images.closeIcon.url}
          width={24}
          onClick={onClose}
        />
      </S.CloseButton>

      <S.BackgroundBlurOne
        alt={Assets.images.circleOfBlurs.alt}
        src="https://cdn.brain.fm/images/circle_of_blurs2.webp"
      />
      <S.BackgroundBlurTwo
        alt={Assets.images.circleOfBlurs.alt}
        src="https://cdn.brain.fm/images/circle_of_blurs2.webp"
      />
    </S.Wrapper>
  );
};
