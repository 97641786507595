import { MouseEvent, useEffect, useRef, useState } from 'react';

import { REVIEWS } from './data';
import * as S from './Reviews.styles';

export const Review = () => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(true);

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);

  const updateScrollState = () => {
    const container = scrollRef.current;
    if (container) {
      const newMaxScroll = container.scrollWidth - container.clientWidth;

      const scrollLeft = container.scrollLeft;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < newMaxScroll);
    }
  };

  useEffect(() => {
    updateScrollState();

    const handleResize = () => {
      updateScrollState();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleScroll = () => {
    updateScrollState();
  };

  const scrollBy = (direction: 'left' | 'right') => {
    const container = scrollRef.current;
    if (container) {
      const scrollAmount = container.clientWidth * 0.35; // Scroll by 1 block (adjust as needed)
      container.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const startDragging = (e: MouseEvent) => {
    const container = scrollRef.current;
    if (container) {
      setIsDragging(true);
      setStartX(e.pageX - container.offsetLeft);
      setScrollLeft(container.scrollLeft);
    }
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  const onDragging = (e: MouseEvent) => {
    if (!isDragging) return;

    const container = scrollRef.current;
    if (container) {
      const x = e.pageX - container.offsetLeft;
      const walk = (x - startX) * 4; // The 4 multiplier adjusts the sensitivity
      container.scrollLeft = scrollLeft - walk;
    }
  };

  return (
    <S.Wrapper>
      <S.ScrollContainer
        ref={scrollRef}
        showLeftFade={canScrollLeft}
        showRightFade={canScrollRight}
        onMouseDown={startDragging}
        onMouseLeave={stopDragging}
        onMouseMove={onDragging}
        onMouseUp={stopDragging}
        onScroll={handleScroll}
      >
        {REVIEWS.map(review => (
          <S.ReviewBlock key={review.byline}>
            <S.CirclesImg src="https://cdn.brain.fm/images/marketing/review_circles.webp" />
            <S.BgImg src="https://cdn.brain.fm/images/marketing/review_background.webp" />
            <S.CardMainContent>
              <p>{review.tweet}</p>
            </S.CardMainContent>
            <S.Hr />
            <S.CardBottomData>
              <S.Author>
                <img
                  alt={review.byline}
                  src={review.image}
                  style={{ width: '40px', borderRadius: '50%' }}
                />
                <h4>{review.byline}</h4>
              </S.Author>
              <S.Stars src="https://cdn.brain.fm/images/marketing/stars.webp " />
            </S.CardBottomData>
          </S.ReviewBlock>
        ))}
      </S.ScrollContainer>

      <S.ButtonContainer>
        <S.ScrollButton disabled={!canScrollLeft} onClick={() => scrollBy('left')}>
          <img alt="left" src="https://cdn.brain.fm/images/marketing/chevron_left.svg" />
        </S.ScrollButton>
        <S.ScrollButton disabled={!canScrollRight} onClick={() => scrollBy('right')}>
          <img alt="right" src="https://cdn.brain.fm/images/marketing/chevron_right.svg" />
        </S.ScrollButton>
      </S.ButtonContainer>
    </S.Wrapper>
  );
};
