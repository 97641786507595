import styled from 'styled-components';

import { H2 } from '../../../../_core/Typography';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4rem;
  padding: 0 20px;
`;

export const Heading = styled(H2)``;

export const Subheader = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: #ffffffbf;
`;

export const TestimonialWrapper = styled.div`
  width: 100%;
  padding: 0 0 2rem;
  overflow-x: hidden;
`;
