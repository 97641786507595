import styled from 'styled-components';

import { CopiesSetData } from '../../../../../../pages';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 0 1rem 0;
`;

const Text = styled.p`
  text-transform: uppercase;
  font-size: 0.65rem;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.6;
  letter-spacing: 0.15rem;
  text-align: left;
`;

const Image = styled.img`
  width: 100%;
  max-width: 500px;
  height: auto;
  object-fit: contain;
`;

type Props = {
  copies: CopiesSetData;
};

export const FeaturedIn = ({ copies }: Props) => {
  return (
    <Container>
      {copies.heroBottomTitle && (
        <Text dangerouslySetInnerHTML={{ __html: copies.heroBottomTitle }} />
      )}
      {copies.heroBottomImage?.fields?.file?.url && (
        <Image alt={copies.heroBottomTitle || ''} src={copies.heroBottomImage?.fields?.file?.url} />
      )}
    </Container>
  );
};

export default FeaturedIn;
