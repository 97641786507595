import styled from 'styled-components';

const Container = styled.div<{ isClose: boolean }>`
  transform: ${({ isClose }) => (isClose ? 'rotate(45deg)' : 'rotate(0deg)')};
  transform-origin: 50% 50% 0;
  transition: all 0.2s ease-in-out;
  margin-right: 1.25rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  padding: 5px;
  flex-shrink: 0;
  ${({ isClose }) =>
    isClose
      ? `
    background-color: rgb(114, 112, 119);
    border-color: rgb(114, 112, 119);
    `
      : ''};
`;

const TopBar = styled.div<{ isClose: boolean }>`
  background-color: #ffffff;
  border-radius: 10px;
  transform: none;
  transform-origin: 50% 50% 0px;
  height: 2px;
  left: calc(50% - 16px / 2);
  top: calc(50% - 2px / 2);
  overflow: visible;
  position: absolute;
  width: 16px;
  transition: all 0.2s ease-in-out;
  ${({ isClose }) =>
    isClose
      ? `
    background-color: black;
    `
      : ''};
`;

const BottomBar = styled.div<{ isClose: boolean }>`
  background-color: #ffffff;
  border-radius: 10px;
  transform: none;
  transform-origin: 50% 50% 0px;
  height: 16px;
  left: calc(50% - 2px / 2);
  overflow: visible;
  position: absolute;
  top: calc(50% - 16px / 2);
  width: 2px;
  transition: all 0.2s ease-in-out;
  ${({ isClose }) =>
    isClose
      ? `
    background-color: black;
    `
      : ''};
`;

type Props = {
  isClose: boolean;
};

export const QuestionPlusIcon = ({ isClose }: Props) => {
  return (
    <Container isClose={isClose}>
      <TopBar isClose={isClose} />
      <BottomBar isClose={isClose} />
    </Container>
  );
};
