import styled, { keyframes } from 'styled-components';

import { PrimaryButtonContainer } from '../../../../button';

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  background-color: #0d0c11;
  border-radius: 8px;
  display: flex;
  height: 168px;
  justify-content: center;
  max-width: 582px;
  overflow: hidden;
  padding: 24px;
  position: fixed;
  right: 16px;
  top: calc(100vh - 168px - 32px);
  width: 100%;
  z-index: 999;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow:
    0px 0px 2px 0px #00000033,
    0px 8px 24px 0px #0000004d;
  backdrop-filter: blur(50px);

  animation: ${FadeIn} 0.5s ease;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  gap: 16px;
  z-index: 999;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 185px;
  height: 120px;
  border-radius: 8px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 32px;
  flex: 1;
`;

export const Title = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  font-size: clamp(14px, 8vw - 2rem, 16px);
`;

export const Subtitle = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  line-height: 20px;
  text-align: left;
  color: rgba(255, 255, 255, 0.75);
  font-size: clamp(12px, 8vw - 2rem, 14px);
`;

export const ButtonContainer = styled.div`
  display: flex;
`;

export const Button = styled(PrimaryButtonContainer)`
  padding: 0 20px;
  font-size: 12px;
  line-height: 14.4px;
  letter-spacing: 0.1em;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonSmall = styled(Button)``;

export const CloseButton = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
  opacity: 0.5;
  transition: opacity 0.2s;
  cursor: pointer;
  z-index: 9999;
  &:hover {
    opacity: 1;
  }
`;

export const BackgroundBlurOne = styled.img`
  position: absolute;
  top: -80%;
  left: -22%;
  width: 262px;
  height: 262px;
  transform: rotate(270deg) scale(2.2);
  z-index: 1;
  object-fit: contain;
`;

export const BackgroundBlurTwo = styled.img`
  position: absolute;
  width: 326px;
  height: 326px;

  bottom: -175%;
  right: -20%;

  transform: scale(2.2);
  z-index: 1;
  object-fit: contain;
`;
