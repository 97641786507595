import styled, { css } from 'styled-components';

import {mediaQuery} from '../../../../../styles/global';

const TextBase = css`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
`;

export const TracksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Container = styled.div`
  background-color: #40407a;
  border-radius: 20px;
  box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin: 50px 20px 20px 20px;
  overflow: hidden;
  width: 45%;

  ${mediaQuery.maxWidth.desktop} {
    width: 100%;
    margin: 5px 20px 20px 5px;
  }
`;

export const PageTitle = styled.div`
  ${TextBase};
  font-size: 38px;
  margin: 130px 20px 20px 20px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex: 2;
  height: 200px;
  min-width: 200px;
  position: relative;
  width: 200px;

  ${mediaQuery.maxWidth.desktop} {
    height: 300px;
    width: 300px;
  }

  ${mediaQuery.maxWidth.tablet} {
    width: 500px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  justify-content: space-evenly;
  min-width: 300px;
  padding: 12px 20px 12px 30px;

  & > * {
    margin: 5px 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 10px 0;
  width: 100%;
`;

export const TrackHeader = styled.p`
  ${TextBase};
  font-size: 16px;
`;

export const TrackTitle = styled.p`
  ${TextBase};
  font-size: 38px;
`;

export const TrackDescription = styled.p`
  ${TextBase};
  font-size: 16px;
`;
