import styled from 'styled-components';

import { mediaQuery } from '../../../styles/global';

export const Wrapper = styled.div`
  position: relative;
`;

export const Container = styled.div`
  display: flex;
  min-height: 500px;
  position: relative;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  ${mediaQuery.maxWidth.tablet} {
    flex-direction: column-reverse;
  }
`;

export const Left = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
  z-index: 2;
  gap: 40px;

  h2 {
    font-size: 3rem;
  }

  ${mediaQuery.maxWidth.desktop} {
    width: 100%;
    padding: 0 2rem;
  }

  ${mediaQuery.maxWidth.tablet} {
    h2 {
      font-size: 2rem;
    }
  }
`;

export const Right = styled.div`
  display: flex;
  flex: 1;
  padding-right: 2rem;
  justify-content: center;
  align-items: center;

  ${mediaQuery.maxWidth.desktop} {
    width: 100%;
    padding: 0 2rem;
  }
`;

export const ButtonWrapper = styled.div`
  > * {
    transition: 0.3s ease all;
    &:hover {
      scale: 1.1;
    }
  }
  ${mediaQuery.maxWidth.tablet} {
    > * {
      width: 100%;
    }
  }
`;

export const Title = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: 3.5rem;
  max-width: 400px;
  line-height: 56px;

  ${mediaQuery.maxWidth.tablet} {
    font-size: 2rem;
    line-height: 36px;
    max-width: 100%;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.white75};
  font-size: 1.5rem;
  max-width: 488px;
  line-height: 36px;

  ${mediaQuery.maxWidth.tablet} {
    font-size: 1.125rem;
    line-height: 24px;
    max-width: 100%;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  position: relative;
  min-width: 300px;
  max-width: 505px;
  padding: 5.5rem 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1.164px solid rgba(255, 255, 255, 0.1);
  background: #0D0C11;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
  z-index: 2;
`;

export const Blur = styled.img`
  pointer-events: none;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 1;
  object-fit: contain;
`;

export const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background: #ffffff1f;
  margin-top: 120px;
  margin-bottom: 40px;

  ${mediaQuery.maxWidth.tablet} {
    margin-top: 78px;
  }
`;