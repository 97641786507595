import styled from 'styled-components';

export const H1 = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${({ theme }) => theme.colors.white};
  font-size: 4rem;
  margin: 0;
`;

export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${({ theme }) => theme.colors.white};
  font-size: 2rem;
  margin: 0;
  line-height: 100%;
`;

export const H3 = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${({ theme }) => theme.colors.white};
  font-size: 0.825rem;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.125rem;
`;
