import Image from 'next/image';
import styled, { keyframes } from 'styled-components';

import { Breakpoints, mediaQuery } from '../../../../../styles/global';
import { Assets } from '../../../../../utils/assets';

const HeroTextKeyframe = keyframes`
  0% {
    opacity: 0;
    top: 20px;
   }
  100% {
    opacity: 1;
    top: 0;
  }
`;

const AuraKeyframe = keyframes`
  0% {
    opacity: 0;
    transform: scale(30%) translate3d(0, 0, 0);
   }
  100% {
    opacity: 1;
    transform: scale(100%) translate3d(0, 0, 0);
  }
`;
const MobileAuraKeyframe = keyframes`
  0% {
    opacity: 0;
    transform: scale(30%) translate3d(0, 0, 0);
   }
  100% {
    opacity: 1;
    transform: scale(170%) translate3d(0, 0, 0);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 500px;
  width: 100%;
  position: relative;
  ${mediaQuery.maxWidth.desktop} {
    flex-direction: column-reverse;
    height: auto;
  }
  max-width: 1400px;
  margin: 0 auto;
  overflow: visible;
`;

export const Content = styled.div`
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.25rem;
  ${mediaQuery.maxWidth.desktop} {
    width: 100%;
    flex-direction: column;
    height: auto;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  min-height: 500px;
  width: 53%;
  ${mediaQuery.maxWidth.desktop} {
    order: 2;
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: auto;
  }
`;

export const DesktopImageBlock = styled.div`
  position: absolute;
  right: -10vw;
  top: 55%;
  transform: translateY(-50%);
  min-height: 500px;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mediaQuery.minWidth.custom(1880)} {
    top: 52%;
  }
  ${mediaQuery.maxWidth.desktop} {
    display: none;
  }
`;

export const MobileImageBlock = styled.div`
  position: relative;
  order: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  ${mediaQuery.minWidth.custom(Breakpoints.desktop + 1)} {
    display: none;
  }
  img {
    width: 90%;
    max-width: 350px;
    height: auto;
  }
`;

export const HeroText = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.white75};
  font-size: 1.5rem;
  max-width: 35rem;
  opacity: 0;
  position: relative;
  top: 20px;
  animation: ${HeroTextKeyframe};
  animation-delay: 0.5s;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  margin-bottom: 2rem;
  ${mediaQuery.maxWidth.phone} {
    font-size: 1rem;
  }
`;

export const ButtonText = styled.div`
  margin: 0 0.5rem;
`;

export const SupportText = styled.div`
  font-size: 0.7rem;
  padding: 10px;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  opacity: 0.7;
`;

export const FeaturedContainer = styled.div`
  max-width: 400px;
  animation: ${HeroTextKeyframe};
  animation-delay: 1.5s;
  animation-duration: 1500ms;
  animation-fill-mode: forwards;
  opacity: 0;
  position: relative;
`;

export const Caption = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${({ theme }) => theme.colors.white};
  font-size: 4rem;
  line-height: 4rem;
  margin: 0;
  animation: ${HeroTextKeyframe};
  animation-delay: 0s;
  animation-duration: 1000ms;
  animation-fill-mode: forwards;
  top: 20px;
  position: relative;
  ${mediaQuery.maxWidth.desktop} {
    font-size: 2.6rem;
    line-height: 2.6rem;
  }
`;

export const FocusAura = styled(Image)`
  position: absolute;
  user-select: none;
  opacity: 0;
  transform: scale(30%) translate3d(0, 0, 0);
  animation: ${MobileAuraKeyframe};
  max-width: 400px;
  animation-delay: 0.5s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  width: 25rem;
`;

export const Background = styled.div`
  background: url(${Assets.images.gradientTrackOct3.url}) no-repeat;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  opacity: 0;
  will-change: filter;
  filter: blur(140px);
  transform: scale(30%) translate3d(0, 0, 0);
  animation: ${AuraKeyframe};
  animation-delay: 0.5s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  ${mediaQuery.maxWidth.desktop} {
    display: none;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  text-align: center;
  width: 520px;
  height: 65px;
  align-items: flex-start;
  position: relative;
  margin: 20px 0;
  gap: 15px;
  opacity: 0;
  animation: ${HeroTextKeyframe};
  animation-delay: 1s;
  animation-duration: 1500ms;
  animation-fill-mode: forwards;

  ${mediaQuery.maxWidth.desktop} {
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }

  ${mediaQuery.maxWidth.tablet} {
    flex-direction: column;
    width: 260px;
  }
`;

export const PlayIcon = styled.span`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const PlayButtonText = styled.div`
  margin: 0 0.5rem;
  display: flex;
`;

export const Button = styled.div`
  ${mediaQuery.maxWidth.tablet} {
    margin-top: 10px;
  }
`;
