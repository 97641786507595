import Image from 'next/legacy/image';
import React from 'react';

import { NewMusicMondayProps } from '../../../../../pages/newMusicMonday';
import { PrimaryButtonLink } from '../../../../button';
import * as S from './TracksList.styles';

export const TracksList = (props: { data?: NewMusicMondayProps }): JSX.Element => {
  const { data } = props;

  return (
    <>
      <S.PageTitle>
        { data?.fields.title }
      </S.PageTitle>

      <S.TracksContainer>
        {data?.fields.tracks.map((track) => (
          <S.Container key={track.fields.title}>
            <S.ImageContainer>
              <Image
                alt={track.fields.title}
                height={500}
                objectFit="cover"
                priority
                src={`https:${track.fields.image.fields.file.url}`}
                width={500}
              />
            </S.ImageContainer>
            <S.TextContainer>
              <S.TrackHeader>{track.fields.headerText}</S.TrackHeader>
              <S.TrackTitle>{track.fields.title}</S.TrackTitle>
              <S.TrackDescription>{track.fields.description}</S.TrackDescription>
              <S.ButtonWrapper>
                <PrimaryButtonLink
                  analyticsEvent={{ category: 'New Music Monday', action: 'Listen' }}
                  href={track.fields.ctaLink}
                >
                  {track.fields.ctaText}
                </PrimaryButtonLink>
              </S.ButtonWrapper>
            </S.TextContainer>
          </S.Container>
        ))}
      </S.TracksContainer>
    </>
  );
};
