import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/navigation';
import React, { useContext } from 'react';
import styled from 'styled-components';

import { ModalDispatchContext, ModalStateContext } from '../../../context/modalContext';
import { CopiesSetData } from '../../../pages';
import { NewMusicMondayProps } from '../../../pages/newMusicMonday';
import { DeviceTypes } from '../../../utils/getDeviceType';
import CTABox from '../../_core/CTAbox/CTAbox';
import { PartnerType } from '../../header/components/PartnerBanner';
import MaxWidth from '../../shared/MaxWidth';
import { DownloadMacAppModal } from './components/DownloadMacAppModal';
import { FAQ } from './components/FAQ';
import Hero from './components/Hero';
import TestimonialSection from './components/Testimonials/Testimonials';
import TracksList from './components/TracksList';
import VideoPoster from './components/VideoPoster';

const Body = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: clip;
  overflow-y: visible;
`;

const HeroContainer = styled.div<{ minHeight: string }>`
  position: relative;
  min-height: ${props => props.minHeight};
`;

interface Props {
  copiesSet: CopiesSetData;
  deviceType?: DeviceTypes;
  newMusicMonday?: NewMusicMondayProps;
  partner?: PartnerType;
  shouldNotIndex?: boolean;
  downloadMacAppVisible?: boolean;
  onDownloadMacAppClose?: () => void;
}

const PartnerHero = dynamic(() => import('./components/PartnerHero'), {
  ssr: true,
});
const ProductDescription = dynamic(() => import('./components/ProductDescription'), {
  ssr: true,
});

export const HomePage: React.FC<Props> = ({
  copiesSet,
  deviceType = DeviceTypes.Desktop,
  newMusicMonday = undefined,
  partner = undefined,
  shouldNotIndex = false,
}) => {
  const router = useRouter();
  const modalState = useContext(ModalStateContext);
  const setModalState = useContext(ModalDispatchContext);

  const handleMacAppModalClose = () => {
    setModalState({ downloadMacAppVisible: false });
  };

  const handleMacAppDownload = () => {
    handleMacAppModalClose();
    router.push('/downloadB');
  };

  return (
    <Body>
      {modalState.downloadMacAppVisible ? (
        <DownloadMacAppModal onClose={handleMacAppModalClose} onNavigate={handleMacAppDownload} />
      ) : null}
      <Head>{shouldNotIndex ? <meta content="noindex, nofollow" name="robots" /> : null}</Head>
      <HeroContainer minHeight={partner ? 'auto' : '100vh'}>
        {!newMusicMonday &&
          (partner ? (
            <MaxWidth>
              <PartnerHero partner={partner} />
            </MaxWidth>
          ) : (
            <Hero copies={copiesSet} deviceType={deviceType} />
          ))}
        {newMusicMonday && (
          <MaxWidth>
            <TracksList data={newMusicMonday} />
          </MaxWidth>
        )}
      </HeroContainer>
      <ProductDescription copies={copiesSet} />
      <TestimonialSection />
      <div id="faq" />
      <VideoPoster />
      <MaxWidth>
        <FAQ />
      </MaxWidth>
      {partner ? (
        <MaxWidth>
          <PartnerHero partner={partner} />
        </MaxWidth>
      ) : (
        <CTABox />
      )}
    </Body>
  );
};
