import { useRef } from 'react';

import { Review } from '../../../../reviews';
import MaxWidth from '../../../../shared/MaxWidth';
import * as S from './Testimonials.styles';

export const TestimonialSection = () => {
  const headerRef = useRef(null);

  return (
    <S.Container>
      <MaxWidth ref={headerRef}>
        <S.Heading>Join millions of others...</S.Heading>
        <S.Subheader>Brain.fm users win the day, everyday — see what they have to say!</S.Subheader>
        <S.TestimonialWrapper>
          <Review />
        </S.TestimonialWrapper>
      </MaxWidth>
    </S.Container>
  );
};

export default TestimonialSection;
