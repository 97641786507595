import { data } from './constants/data';
import { Question } from './Question';
import { BgImg, PageTitle, PageWrapper } from './Question.styles';

export const FAQ = () => {
  return (
    <PageWrapper>
      <BgImg alt="faq background" src="https://cdn.brain.fm/images/marketing/faq_bg.webp" />
      <PageTitle>Frequently asked questions</PageTitle>
      {data.map(({ question, answer }, i) => (
        <Question key={i} answer={answer} question={question} />
      ))}
    </PageWrapper>
  );
};
